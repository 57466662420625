<template>
  <div class="row">
    <div class="col-3">
      <div>
        <b-card
          header="Filtrează rezultatele"
          header-tag="header"
        >
          <div class="mb-3">
            <b>Statut comandă</b>
            <VueSelect
              :multiple="true"
              :options="orderStatesList"
              :reduce="selected => selected.state"
              taggable
              v-model="filters.states"
              :filterable="false"
            ></VueSelect>
          </div>
          <div class="mb-3">
            <b>De la</b>
            <div class="row">
              <div class="col-8">
                <b-form-datepicker
                  size="sm"
                  v-model="filters.date_from"
                  reset-button
                  locale="ro"
                >
                </b-form-datepicker>
              </div>
              <div class="col-4">
                <b-form-timepicker
                  :disabled="filters.date_from === null"
                  v-model="timeStart"
                  @input="filters.time_from = $event"
                  size="sm"
                  locale="ro"
                >
                </b-form-timepicker>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <b>Până la</b>
            <div class="row">
              <div class="col-8">
                <b-form-datepicker
                  size="sm"
                  v-model="filters.date_to"
                  reset-button
                  locale="ro"
                >
                </b-form-datepicker>
              </div>
              <div class="col-4">
                <b-form-timepicker
                  :disabled="filters.date_to === null"
                  v-model="timeEnd"
                  @input="filters.time_to = $event"
                  size="sm"
                  locale="ro"
                >
                </b-form-timepicker>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <b>Comenzi parteneri</b>
            <VueSelect
              :options="companiesOrders"
              :reduce="selected => selected.state"
              v-model="filters.processedBy"
              :filterable="false"
            ></VueSelect>
          </div>
          <div class="mb-3">
            <b>Șofer</b>
            <UserSelect
              :roles="['driver']"
              @input="filters.driver_id = $event ? $event.id : null"
              v-model="driverName"
            ></UserSelect>
          </div>
          <div class="mb-3">
            <b>Client</b>
            <UserSelect
              :roles="['client']"
              @input="filters.client_id = $event ? $event.id : null"
              v-model="clientName"
            ></UserSelect>
          </div>
          <div class="mb-3">
            <b>Operator</b>
            <UserSelect
              :roles="['dispatcher']"
              @input="filters.dispatcher_id = $event ? $event.id : null"
              v-model="dispatcherName"
            ></UserSelect>
          </div>
          <div class="mb-3">
            <b>Companie</b>
            <UserSelect
              :roles="['company']"
              @input="filters.company_id = $event ? $event.id : null"
              v-model="companyName"
            ></UserSelect>
          </div>
          <div class="mb-3">
            <b>Modalitate de plata</b>
            <VueSelect
              :options="orderPaymentMethods"
              :reduce="selected => selected.state"
              taggable
              v-model="filters.payment_method"
              :filterable="false"
            ></VueSelect>
          </div>
          <div class="mb-3">
            <b>Sursa comanda</b>
            <VueSelect
              :options="orderSources"
              :reduce="selected => selected.state"
              taggable
              v-model="filters.order_source"
              :filterable="false"
            ></VueSelect>
          </div>
          <div class="mb-3">
            <b>Adresa</b>
          <AddressSelect
            v-model="selectedPlace"
            @input="place = $event ? $event : []"
          >
          </AddressSelect>
          </div>
          <div class="mb-3 d-none">
            <b>Dispecerat</b>
            <CompanySelect v-model="filterCompanyIds" :multiple="true"></CompanySelect>
          </div>
        </b-card>
      </div>
    </div>
    <div class="col-9 reports_table" id="reports_table">
      <div class="row justify-content-start align-items-center mb-2">
        <div class="col-2">Comenzi pe pagina: <b>30</b></div>
        <div class="col-2">Total: <b>{{ total }}</b></div>
        <div class="col-2">
          <b-button @click="savePdf" :disabled="isSavingPdf" variant="outline-dark" size="sm"><i :class="{ 'fa fa-file-pdf-o': !isSavingPdf, 'fa fa-spinner fa-pulse': isSavingPdf }"></i> Salveaza PDF</b-button>
        </div>
      </div>
      <div :class="{'data-loading': preloader}" class="position-relative">
        <Vuetable
          ref="vuetable"
          class="table table-sm table-hover table-font-sm"
          :api-mode="false"
          :fields="tableFields"
          :append-params="filters"
          :http-fetch="fetchApiData"
          :transform="transformApiResponse"
          :detail-row-component="OrderDetails"
          track-by="id"
          :data="localData"
          @vuetable:row-clicked="onRowClicked"
          @vuetable:loaded="preloader = false"
        >
          <div slot=""></div>
          <div slot="created_at" slot-scope="props">
            <DateTime :date-time="props.rowData.orderDate"></DateTime>
          </div>
          <div slot="driver" slot-scope="props">
            <b-badge>{{ props.rowData.indicativ }}</b-badge>
          </div>
          <div  slot="call" slot-scope="props">
          <span v-if="props.callDuration">
            {{ callDuration }}
          </span>
            <span v-else>*</span>
          </div>
        </Vuetable>
        <div v-if="preloader" class="position-absolute" style="top: 48%; left: 48%;">
          <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
        </div>
      </div>
      <div class="text-center d-flex justify-content-center mt-3">
        <b-button-group>
          <b-button :disabled="pagination.page === 1" @click="prevPage">PREV</b-button>
          <b-input style="width: 60px; text-align: center;" v-model.number="pagination.page"></b-input>
          <b-button :disabled="localData.length < pagination.per_page" @click="nextPage">NEXT</b-button>
        </b-button-group>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import Vuetable from 'vuetable-2'
import CssConfig from '@/assets/other/VuetableBootstrap4Config'
import OrderDetails from '@/components/reports/OrderDetails'
import VueSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import UserSelect from '@/components/common/UserSelect'
import QueryString from 'query-string'
import DateTime from '@/components/common/DateTime'

import pdfMake from 'pdfmake/build/pdfmake.js'
import pdfFonts from 'pdfmake/build/vfs_fonts.js'
import AddressSelect from '@/components/common/AddressSelect'
import CompanySelect from '@/components/common/CompanySelect'
import dayjs from 'dayjs'

export default {
  name: 'OrdersReport',
  components: {
    CompanySelect,
    AddressSelect,
    DateTime,
    UserSelect,
    Vuetable,
    VueSelect
  },
  data () {
    return {
      preloader: false,
      localData: [],
      total: null,
      totalSum: 0,
      selectedPlace: null,
      place: [],
      isSavingPdf: false,
      driverName: null,
      clientName: null,
      dispatcherName: null,
      companyName: null,
      tableFields: [
        {
          name: 'orderId',
          title: '#',
          formatter (value) {
            return '#' + value
          }
        },
        {
          name: 'orderDate',
          title: 'Data',
          formatter (value) {
            return dayjs(value + 'Z').local().format('DD.MM.YYYY HH:mm')
          }
        },
        {
          name: 'clientName',
          title: 'Client',
          formatter (value) {
            return value || '-'
          }
        },
        {
          name: 'callDuration',
          title: 'Durata apel',
          formatter (s) {
            if (s) { return (s - (s %= 60)) / 60 + (s > 9 ? ':' : ':0') + s }
          }
        },
        {
          name: 'driver',
          title: 'Șofer'
        },
        {
          name: 'cost',
          title: 'Suma',
          formatter (value) {
            return typeof value === 'number' ? value.toFixed(2) : '*'
          }
        },
        {
          name: 'placeName',
          title: 'Adresa preluare'
        },
        {
          name: 'state',
          title: 'Statut',
          formatter (value) {
            let statut = '-'
            if (value === 'created') {
              statut = '*'
            }
            if (value === 'new') {
              statut = 'N'
            }
            if (value === 'finished') {
              statut = 'F'
            }
            if (value === 'assigned') {
              statut = 'A'
            }
            if (value === 'canceled') {
              statut = 'C'
            }
            return statut
          }
        },
        {
          name: 'paymentType',
          title: 'Plată',
          formatter (value) {
            let plata = '-'
            if (value === 'card') {
              plata = 'C'
            }
            if (value === 'cash') {
              plata = 'N'
            }
            if (value === 'protocol') {
              plata = 'P'
            }
            return plata
          }
        }
      ],
      css: CssConfig,
      OrderDetails: OrderDetails,
      filters: {
        date_from: null,
        date_to: null,
        time_from: null,
        time_to: null,
        processedBy: null,
        states: [],
        driver_id: null,
        client_id: null,
        dispatcher_id: null,
        company_id: null,
        payment_method: null,
        companyIds: ''
      },
      filterCompanyIds: [],
      pagination: {
        per_page: 30,
        page: 1
      },
      companiesOrders: [
        { label: '-', state: null },
        { label: 'Trimise', state: 'sended' },
        { label: 'Primite', state: 'received' }
      ],
      orderStatesList: [
        { label: 'Nou', state: 'new' },
        { label: 'Creat', state: 'created' },
        { label: 'Alocat', state: 'assigned' },
        { label: 'Finalizat', state: 'finished' },
        { label: 'Anulat', state: 'canceled' }
      ],
      orderPaymentMethods: [
        { label: 'Card', state: 'card' },
        { label: 'Cash', state: 'cash' },
        { label: 'Protocol', state: 'protocol' }
      ],
      orderSources: [
        { label: 'Apel telefonic', state: 'from_call' },
        { label: 'Aplicatie mobila', state: 'from_app' }
      ],
      currentPaginationData: null,
      timeStart: '00:00',
      timeEnd: '23:59',
      orderAddress: []
    }
  },
  mounted () {
    this.$axios.get(this.reactiveApiUrl).then((response) => {
      this.localData = response.data.data
      this.total = response.data.total
    })
  },
  computed: {
    apiFilters () {
      const filters = Object.assign(JSON.parse(JSON.stringify(this.filters)), this.pagination)
      filters.state = filters.states ? filters.states.join(',') : null
      filters['addresses[]'] = this.place.map(a => a.place_id)
      delete filters.states
      return filters
    },
    reactiveApiUrl () {
      return config.baseApiUrl + '/api/orders-for-report?' + QueryString.stringify(this.apiFilters)
    },
    endedCallDuration () {
      return this.$day.duration(
        this.$day.utc(this.order.call.ended_at).diff(
          this.$day.utc(this.order.call.started_at)
        )
      ).format('mm:ss')
    }
  },
  methods: {
    fetchApiData (apiUrl, httpOptions) {
      document.getElementById('reports_table').classList.add('data-loading')
      return this.$axios.get(apiUrl, httpOptions)
    },
    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page--
      }
    },
    nextPage () {
      this.pagination.page++
    },
    transformApiResponse (response) {
      document.getElementById('reports_table').classList.remove('data-loading')
      this.totalSum = new Intl.NumberFormat('fr-FR').format(response.total_sum)
      return response
    },
    onRowClicked (props) {
      this.$store.state.dispatcherDashboard.orderInPreview = props.data.orderId
      this.$root.$emit('bv::toggle::collapse', 'order-sidebar')
    },
    savePdf () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      const filtersForPdf = Object.assign({}, this.apiFilters)
      filtersForPdf.per_page = 100000
      const apiUrlForPdf = config.baseApiUrl + '/api/orders-for-report?' + QueryString.stringify(filtersForPdf)
      const component = this
      component.isSavingPdf = true
      component.$axios.get(apiUrlForPdf).then(function (response) {
        const orders = response.data.data.map((row) => {
          return [
            row.orderId,
            row.clientName,
            row.indicativ,
            row.state,
            row.paymentType,
            row.orderDate
          ]
        })
        const document = {
          pageOrientation: 'landscape',
          content: [
            {
              layout: 'lightHorizontalLines',
              table: {
                headerRows: 1,
                widths: ['*', '*', '*', '*', '*', '*'],
                body: [
                  [
                    { text: 'Comanda', bold: true, margin: [0, 3] },
                    { text: 'Client', bold: true, margin: [0, 3] },
                    { text: 'Sofer', bold: true, margin: [0, 3] },
                    { text: 'Statut', bold: true, margin: [0, 3] },
                    { text: 'Plata', bold: true, margin: [0, 3] },
                    { text: 'Data', bold: true, margin: [0, 3] }
                  ]
                ].concat(orders)
              }
            }
          ],
          footer: function (currentPage, pageCount) {
            return currentPage.toString() + ' of ' + pageCount
          }
        }
        const pdfName = 'Export_' + new Date().toLocaleDateString('ro-RO').replaceAll('.', '_') + '_' + new Date().toLocaleTimeString('ro-RO').replaceAll(':', '_')
        pdfMake.createPdf(document).download(pdfName, function () { component.isSavingPdf = false })
      }).catch(() => {
        this.$toasted.error('Fisierul PDF nu a putut fi procesat', {
          duration: 3000
        })
      })
    }
  },
  watch: {
    filterCompanyIds: function (values) {
      this.filters.companyIds = values.join(',')
    },
    reactiveApiUrl: function (value) {
      this.$axios.get(value).then((response) => {
        this.localData = response.data.data
        this.total = response.data.total
      })
    },
    filters: {
      handler: function () {
        this.preloader = true
        this.pagination.page = 1
      },
      deep: true
    },
    'pagination.page': function () {
      this.preloader = true
    }
  }
}
</script>

<style>

.data-loading {
    opacity: 0.1;
}

</style>
